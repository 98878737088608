<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '630px' }"
    :header="'Imagenes Producto : ' + (detalle.descripcion ?? 'cargando ...')"
    :modal="true"
    class="p-fluid"
    :maximizable="true"
    @hide="cerrarModal"
    :loading="loading"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div v-for="imagen in imagenes" :key="imagen.id">
          <div
            class="field col-12 md:col-9 flex flex-wrap justify-content-center"
          >
            <Image
              :src="'data:image/png;base64, ' + imagen.image"
              width="200"
              height="200"
              alt="imagen"
              preview
            >
            </Image>
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-sm"
              @click="eliminarImagen(imagen.id)"
              v-tooltip.top="'Quitar Imagen'"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex md:justify-content-end">
        <!-- :maxFileSize="1000000" -->
        <FileUpload
          mode="basic"
          name="demo[]"
          accept="image/*"
          :customUpload="true"
          chooseLabel="CARGAR NUEVA IMAGEN"
          @uploader="onUpload"
          :auto="true"
          class="p-button-danger p-button-lg p-button-outlined p-button-rounded mr-2"
        />
        <Button
          label="CERRAR"
          icon="pi pi-times"
          class="p-button-secondary p-button-lg p-button-outlined p-button-rounded"
          @click="cerrarModal"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import ProductService from "@/service/ProductService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    producto_id: {
      type: Number,
      default: 0,
    },
  },
  productoService: null,
  data() {
    return {
      mostrarModal: this.show,
      producto: this.producto_id,
      detalle: [],
      imagenes: [],
      loading: true,
    };
  },
  created() {
    this.productoService = new ProductService();
  },
  methods: {
    eliminarImagen(imagen_id) {
      this.productoService.deleteImage(imagen_id).then(() => {
        this.buscarProducto();
        this.$toast.add({
          severity: "success",
          summary: "Actualizado",
          detail: "Imagen eliminada correctamente",
          life: 3000,
        });
      });
    },
    cerrarModal() {
      this.mostrarModal = false;
      /*  this.detalle = [];
      this.imagenes = []; */
      this.$emit("closeModal");
      this.$emit("actualizarListado");
    },
    buscarProducto() {
      this.productoService.getProductoImages(this.producto_id).then((data) => {
        this.detalle = data.producto;
        this.imagenes = data.images;
        this.loading = false;
      });
    },
    onUpload(event) {
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("imagen", archivo);
      formData.append("producto_id", this.producto_id);

      this.productoService
        .uploadImage(formData)
        .then(() => {
          this.buscarProducto();
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: "Imagen cargada correctamente",
            life: 3000,
          });
          this.$emit("actualizarListado");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    show: function (val) {
      this.mostrarModal = val;
    },
    producto_id: function (val) {
      this.producto = val;
      this.buscarProducto();
    },
  },
};
</script>
